import {TemplateResult, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import ToolButtonElement from 'gmfapi/elements/ToolButtonElement';
import 'bootstrap/js/src/tooltip';
import View from 'ol/View';
import {msg, localized} from '@lit/localize';

@localized()
@customElement('feedback-button')
export class ToolButtonFeedback extends ToolButtonElement {
  @property({type: String}) lang: string = 'de';

  constructor() {
    super('feedback');
  }

  render(): TemplateResult {
    return html`
      <button
        part="button"
        class="btn btn-default"
        data-toggle="tooltip"
        data-placement="left"
        data-original-title="${msg('Give feedback')}"
        @click=${() => this.click_()}
      >
        <span class="fas fa-file-signature"></span>
      </button>
    `;
  }
}
