import {TemplateResult, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import ToolButtonElement from 'gmfapi/elements/ToolButtonElement';
import 'bootstrap/js/src/tooltip';
import {Subscription} from 'rxjs';
import mapModel from 'gmfapi/store/map';
import configModel from 'gmfapi/store/config';
import View from 'ol/View';
import Map from 'ol/Map.js';
import {msg, localized} from '@lit/localize';

@localized()
@customElement('geo-shop-open-button')
export class ToolButtonGeoshopOpen extends ToolButtonElement {
  @property({type: String}) lang: string = 'de';
  private view?: View;
  private subscriptions: Subscription[] = [];
  geoshopUrl: string;

  constructor() {
    super('geo-shop-open');
    const configSubscription = configModel.getConfig().subscribe((config) => {
      if (!config) {
        return;
      }
      this.geoshopUrl = config.ugspGeoshopUrl ? `https://${config.ugspGeoshopUrl}/` : '';
      configSubscription.unsubscribe();
    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.subscriptions.push(
      mapModel.getMap().subscribe((map: Map) => {
        if (!map) {
          return;
        }
        this.view = map.getView();
      }),
    );
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  clickStore() {
    const url = this.generateGeoshopUrl(this.geoshopUrl, this.view?.getViewStateAndExtent().extent as []);
    window.open(url, '_blank');
  }

  generateGeoshopUrl(baseUrl: string, extent: [] | undefined): string {
    return `${baseUrl}de/welcome?bounds=${extent}`;
  }

  render(): TemplateResult {
    return html`
      <button
        part="button"
        @click=${() => {
          this.clickStore();
        }}
        class="btn btn-default ${this.active_ ? 'active' : ''}"
        data-toggle="tooltip"
        data-placement="left"
        data-original-title="${msg('Go to GeoShop')}"
      >
        <span class="fa-solid fa-cart-shopping"></span>
      </button>
    `;
  }
}
