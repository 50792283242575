import {configureLocalization} from '@lit/localize';

import {sourceLocale, targetLocales} from '../../generated/locale-codes.js';

export const {getLocale, setLocale} = configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: (locale: string) => {
    return import(`../../generated/locale/${locale}.ts`);
  },
});
