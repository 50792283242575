import {html, LitElement, PropertyValues} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import configModel from 'gmfapi/store/config';
import './owner-request.css';
import {unsafeSVG} from 'lit-html/directives/unsafe-svg.js';
import {Subscription} from 'rxjs';
import {getOwnerByEGRID} from '../../terravis_api/terravis.ts';

@customElement('owner-request-window')
export class OwnerRequestWindow extends LitElement {
  @property({type: String}) egrid: string = '';
  @state() parcelData?: Object = undefined;
  @state() personData?: Object = undefined;
  @state() rightData?: Object = undefined;
  @state() loading: boolean = false;
  @state() generalError = false;
  @state() tooManyRequestsError = false;
  subscriptions: Subscription[] = [];
  spinnerSVG: string = '';
  terravisApiUrl: string = '';
  requestNumberAnonymous: number;
  requestNumberLogged: number;
  @state() parcels = [];

  constructor() {
    super();
    const configSubscription = configModel.getConfig().subscribe((config) => {
      if (!config) {
        return;
      }
      this.terravisApiUrl = config.terravisApiUrl ? `${config.terravisApiUrl}/` : '';
      this.requestNumberLogged = config.requestNumberLogged || '';
      this.requestNumberAnonymous = config.requestNumberAnonymous || '';
      configSubscription.unsubscribe();
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.loadSpinnerSVG();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  updated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);
    if (changedProperties.has('egrid')) {
      this.egrid = this.egrid;
    }
  }

  async sendRequest() {
    this.loading = true;
    // this parameter names are to update in case of changes in the yaml file

    const result = await getOwnerByEGRID(this.egrid, this.terravisApiUrl);
    this.loading = false;

    if (result.ok) {
      const data = await result.json();
      if (!data) {
        this.generalError = true;
        console.error('error', 'no data');
      } else {
        this.generalError = false;
        this.tooManyRequestsError = false;
        this.parcels = data.parcels;
      }
    } else {
      if (result.status === 429) {
        console.error('Too Many Requests');
        this.tooManyRequestsError = true;
      } else {
        console.error('Error:', result.statusText);
        this.generalError = true;
      }
    }
  }

  async loadSpinnerSVG() {
    try {
      const response = await fetch('static-frontend/spinner.svg');
      if (response.ok) {
        this.spinnerSVG = await response.text();
      } else {
        console.error('Failed to load spinner SVG:', response.statusText);
      }
    } catch (error) {
      console.error('Error loading spinner SVG:', error);
    }
  }

  formatDate(dateArray: (number | null)[]): string {
    if (dateArray.length < 3) {
      return '';
    }
    const [year, month, day] = dateArray;
    if (year === null || month === null || day === null) {
      return '';
    }
    return `${day}.${month}.${year}`;
  }

  render() {
    return html`
      <div class="owner-request-window">
        <h1>GeoGR Eigentümerabfrage</h1>
        <p>für ${this.egrid}</p>
        <br />
        <div>
          Sie können pro 24h ${this.requestNumberAnonymous} Anfragen starten. ${this.requestNumberLogged} wenn
          sie eingeloggt sind.
        </div>
        <br />
        <button class="owner-send-button btn" @click=${this.sendRequest}>Abfrage starten</button>
        <br />
        ${!this.parcels && !this.loading
          ? html`<br />
              <div>No result data available</div>`
          : html``}
        ${this.loading ? this.renderSpinner() : html``}
        <br />
        <div>
          ${this.parcels[0]?.grundstueck ? this.returnParcelData(this.parcels[0]?.grundstueck) : html``}
          <br />
          ${this.parcels[0]?.recht ? this.returnRightData(this.parcels[0].recht) : html``}
          <br />
          ${this.parcels[0]?.person ? this.returnPersonData(this.parcels[0].person) : html``}
        </div>
        <br />
        ${this.parcels.length > 0
          ? html`<p>Die Aktualität und die Richtigkeit der Daten kann nicht gewährleistet werden.</p>`
          : html``}
        <br />
        ${this.tooManyRequestsError
          ? html`<p class="error">Sie haben die erlaubte Anzahl abfragen pro 24h überschritten!</p>`
          : html``}
        ${this.generalError ? html`<p class="error">Es ist ein Fehler aufgetreten.</p>` : html``}
        <br />
      </div>
    `;
  }

  renderSpinner() {
    return html`
      <div class="owner-request-spinner">
        <i class="fa-solid custom-spinner-loading fa-spin spinner-loading-mask"
          >${unsafeSVG(this.spinnerSVG)}
        </i>
      </div>
    `;
  }

  returnParcelData(parcels: Object[]) {
    const parcelWithEgid = parcels.filter((parcel) => parcel.nummer?.includes(this.egrid));
    if (parcelWithEgid.length === 0) {
      return html``;
    }
    if (parcelWithEgid.length > 1) {
      this.generalError = true;
      console.error('error', 'too many grundstueck');
      return html``;
    } else {
      const parcel = parcelWithEgid[0];
      return html` <div>
        <h2>Grundstückbeschereibung</h2>
        <div class="data-table">
          <div class="label">E_GRID:</div>
          <div>${this.egrid}</div>
          <div class="label">Politische Gemeinde:</div>
          <div>${parcel.gemeinde?.gemeinde?.municipality_name}</div>
          <div class="label">BFS-Nr:</div>
          <div>${parcel.nummer?.split('::')[1]}</div>
          <div class="label">Grundbuchamt:</div>
          <div>${parcel.gbamt?.gbamt?.name}</div>
          <div class="label">Grundstück-Nr:</div>
          <div>${parcel.nummer?.split(':')[1]}</div>
          <div class="label">Fläche:</div>
          <div>${
            parcel.inhalt_liegenschaft && parcel.inhalt_liegenschaft.length > 0
              ? parcel.inhalt_liegenschaft[0].flaechenmass
              : ''
          }</div>
          <div class="label">Plan-Nr:</div>
          <div>${
            parcel.inhalt_liegenschaft && parcel.inhalt_liegenschaft.length > 0
              ? parcel.gbplaene[0].gbplan?.nummer
              : ''
          }</div>
          <div class="label">Bodenbedeckung:</div>
          </br>
          <ul class="owner-request-ul">
            ${
              parcel.bodbd_anteil && parcel.inhalt_liegenschaft.length > 0
                ? parcel.bodbd_anteil.map(
                    (anteil: Object) => html`
                      <li class="owner-request-li">${anteil.bodenbedeckung?.art_bezeichnung}</li>
                    `,
                  )
                : ''
            }
          </ul>
          </br>
          <div class="label">Flurnamen:</div>
          </br>
          <ul class="owner-request-ul">${
            parcel.flurnamen && parcel.flurnamen.length > 0
              ? parcel.flurnamen.map(
                  (flurname: Object) => html` <li class="owner-request-li">${flurname.flurname?.name}</li> `,
                )
              : ''
          }</ul>
          </br>
          <div class="label">Gebäude/Bauten:</div>
          </br>
          <ul class="owner-request-ul">${
            parcel.gebaeude && parcel.gebaeude.length > 0
              ? parcel.gebaeude.map(
                  (gebaeude: Object) => html`
                    <li class="owner-request-li">
                      ${gebaeude.gebaeude?.gebaeudeart_zusatz ||
                      gebaeude.gebaeude?.gebaeudeart_stichwort?.gebaeude_gebaeudeart?.stichwort},
                      Nummer: ${gebaeude.gebaeude?.nummer}
                      ${gebaeude.gebaeude?.adresse.length > 0
                        ? `, ${gebaeude.gebaeude?.adresse[0].gebaeudeeingang_adresse?.strasse}, ${gebaeude.gebaeude?.adresse[0].gebaeudeeingang_adresse?.hausnummer}`
                        : ''}
                    </li>
                  `,
                )
              : ''
          }</ul>
        </div>
      </div>`;
    }
  }

  renderPerson(person: Object) {
    const hasAdresse = person.adresse && person.adresse.length > 0;
    return html`
      <div>
        ${person.vorname} ${person.name}
        ${hasAdresse
          ? `, ${person.adresse[0].adresse?.strasse}, ${person.adresse[0].adresse?.hausnummer}, ${person.adresse[0].adresse?.plz}, ${person.adresse[0].adresse?.ort}`
          : ''}
      </div>
    `;
  }

  renderLegalPerson(person: Object) {
    const hasAdresse = person.adresse && person.adresse.length > 0;
    return html`
      <div>
        ${person.name}, UID: ${person.uid}, mit Sitz in ${person.sitz}
        ${hasAdresse
          ? ` , ${person.adresse[0].adresse.strasse}
        ${person.adresse[0].adresse.hausnummer}, ${person.adresse[0].adresse.plz}
        ${person.adresse[0].adresse.ort}`
          : ''}
      </div>
    `;
  }

  eliminateDuplicates(objects: Object[]): Object[] {
    const uniqueObjects = new Set();
    const result: Object[] = [];
    objects.forEach((obj) => {
      const value = JSON.stringify(obj);
      if (!uniqueObjects.has(value)) {
        uniqueObjects.add(value);
        result.push(obj);
      }
    });
    return result;
  }

  returnPersonData(persons: Object[]) {
    let naturalPersons = persons
      .filter((person) => person.person_stamm?.natuerliche_person)
      .map((person) => person.person_stamm?.natuerliche_person);
    let swissLegalPersons = persons
      .filter((person) => person.person_stamm?.schweizerische_juristische_person)
      .map((person) => person.person_stamm?.schweizerische_juristische_person);

    // Eliminate duplicates
    naturalPersons = this.eliminateDuplicates(naturalPersons);
    swissLegalPersons = this.eliminateDuplicates(swissLegalPersons);

    if (naturalPersons.length === 1) {
      return this.renderPerson(naturalPersons[0]);
    } else if (naturalPersons.length > 1) {
      return html`
        <ul class="owner-request-ul">
          ${naturalPersons.map(
            (person: Object) => html` <li class="owner-request-li">${this.renderPerson(person)}</li> `,
          )}
        </ul>
      `;
    } else if (swissLegalPersons.length === 1) {
      return this.renderLegalPerson(swissLegalPersons[0]);
    } else if (swissLegalPersons.length > 1) {
      return html`
        <ul class="owner-request-ul">
          ${swissLegalPersons.map(
            (person: Object) => html` <li class="owner-request-li">${this.renderLegalPerson(person)}</li> `,
          )}
        </ul>
      `;
    } else {
      return html``;
    }
  }

  returnRightData(rights: Object[]) {
    const parts = rights.filter((right) => right.inhalt_eigentum_anteil.length > 0);
    return html`
      <div>
        <h2>Eigentum</h2>
        ${parts.map(
          (right: Object) => html`
          <div>${
            right.berechtigte && parts.length > 1
              ? right.berechtigte?.replace(/::/g, '/').replace(/:/g, '/')
              : ''
          }</div>
          <div class="label">
            ${
              right.inhalt_eigentum_anteil && right.inhalt_eigentum_anteil.length > 0
                ? `${right.inhalt_eigentum_anteil[0].anteil_zaehler} /
            ${right.inhalt_eigentum_anteil[0].anteil_nenner},
            ${right.inhalt_eigentum_anteil[0].eigentumsform}`
                : ''
            }
          </div>
          <div class="data-table">
            <div>Erwerbsdatum:</div>
            </br>
            <ul class="owner-request-ul">${
              right.erwerbsgrund && right.erwerbsgrund.length > 0
                ? right.erwerbsgrund.map(
                    (erwerbsgrund: Object) => html`
                      <li class="owner-request-li">${this.formatDate(erwerbsgrund.erwerbsgrund?.datum)}</li>
                    `,
                  )
                : ''
            }</ul>
          </div>
        `,
        )}
      </div>
    `;
  }

  createRenderRoot() {
    return this;
  }
}
