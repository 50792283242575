export async function getOwnerByEGRID(egrid: string, terravisApiUrl: string): Promise<any> {
  const url = `${terravisApiUrl}egrid/${egrid}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.error('Error fetching owner data:', error);
    throw error;
  }
}
