import {customElement, state, property} from 'lit/decorators.js';
import {html, css} from 'lit';
import {msg, updateWhenLocaleChanges} from '@lit/localize';
import ToolPanelElement from 'gmfapi/elements/ToolPanelElement';
import configModel from 'gmfapi/store/config';
import panelsModel from 'gmfapi/store/panels';
import {setLocale} from '../../locale/localization.js';

@customElement('proj-feedback')
export class ProjFeedback extends ToolPanelElement {
  @property({type: String}) lang: string = 'de';
  @state()
  private show_send = false;
  @state()
  private permalink: string = window.location.href;
  private email: string = '';
  private email_optional: string = '';
  private feedback_text: string = '';
  private url_: string = '';
  private subscriptions_ = [];

  static styles = [
    ToolPanelElement.styles,
    css`
      .modal-footer {
        border-top: 0.06rem solid var(--color-light);
      }
    `,
  ];

  constructor() {
    super();
    this.initializeLocale();
  }

  async initializeLocale(): Promise<void> {
    await setLocale(this.lang);
    updateWhenLocaleChanges(this);
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.subscriptions_.push(
      configModel.getConfig().subscribe({
        next: (configuration?: any) => {
          if (configuration) {
            this.url_ = new URL(configuration.feedbackPath, configuration.gmfBase).href;
          }
        },
      }),
    );
    window.addEventListener('popstate', () => {
      this.permalink = window.location.href;
    });
  }

  render() {
    return html`${this.getTitle(msg('Give feedback'))}
      <label for="email">${msg('Your email')}</label>
      <input
        input="text"
        placeholder="example@example.com"
        name="email"
        class="form-control"
        id="email"
        .value="${this.email}"
        @input=${(e: any) => {
          this.email = e.target.value;
        }}
      />
      <br />
      <label for="email_optional">${msg('Include somebody in cc')}</label><br />
      <input
        type="text"
        placeholder="example@example.com"
        name="email_optional"
        class="form-control"
        id="email_optional"
        .value="${this.email_optional}"
        @input=${(e: any) => {
          this.email_optional = e.target.value;
        }}
      />
      <br />
      <label for="feedback_text">${msg('Your feedback')}</label><br />
      <textarea
        rows="4"
        cols="40"
        class="form-control"
        id="feedback_text"
        .value="${this.feedback_text}"
        @input=${(e: any) => {
          this.feedback_text = e.target.value;
        }}
        maxlength="1000"
        placeholder="${msg('Maximum 1000 caractères')}"
      >
      </textarea>
      <br />
      <label for="permalink">${msg('This is the url sent:')}</label>
      <input
        type="text"
        name="permalink"
        class="form-control"
        id="permalink"
        .value="${this.permalink}"
        readonly
      />
      <br />
      <button type="submit" class="btn prime" @click="${this.feedbackSubmit}">${msg('Send')}</button>
      ${this.show_send
        ? html`
            <div class="fas fa-spinner fa-spin"></div>
            ${msg('Sending')}
          `
        : ''}`;
  }

  createRenderRoot() {
    return this; // Render template in light DOM
  }

  private feedbackSubmit() {
    if (
      (this.email && this.email.indexOf('@') === -1) ||
      (this.email_optional && this.email_optional.indexOf('@') === -1)
    ) {
      alert(msg("One of the email addresses doesn't look right."));
      return;
    }

    if (!this.feedback_text) {
      alert(msg('Please enter some feedback.'));
      return;
    }

    if (this.feedback_text.length > 1000) {
      alert(msg('Your text is too long.'));
      return;
    }
    this.show_send = true;

    let formdata = new FormData();
    formdata.set('permalink', this.permalink.toString());
    formdata.set('ua', navigator.userAgent);
    formdata.set('email', this.email);
    formdata.set('email_optional', this.email_optional);
    formdata.set('feedback', this.feedback_text);

    fetch(this.url_, {
      method: 'POST',
      body: formdata,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.statusText} (${response.status})`);
        }
        this.show_send = false;
        alert(
          `${msg('Thanks! Your feedback has been sent.')}\n\n${msg(
            'Depending on your request, somebody will contact you soon.',
          )}`,
        );
        panelsModel.closeToolPanel();
      })
      .catch((error) => {
        console.error(error);
        alert(msg('Something went wrong.'));
        this.show_send = false;
      });
  }
}
